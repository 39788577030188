import styled from "styled-components";

export default styled.a`
  text-decoration: none;
  font-weight: bolder;
  color: var(--light);

  &:hover {
    cursor: pointer;
    color: var(--lighter);
  }
`;
