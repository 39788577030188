import React from "react";
import styled from "styled-components";
import LinkBase from "./LinkBase";

const Wrapper = styled.div`
  width: 100%;
  color: var(--dark-brighter);
  font-weight: bold;
  padding: 0.5rem;
  margin-top: -2rem;
`;

export default () => {
  return (
    <Wrapper>
      Made with ♥ by Creativebuilds -{" "}
      <LinkBase href="https://twitter.com/creativebuilds">Twitter</LinkBase> -{" "}
      <LinkBase href="https://youtube.com/c/creativebuilds">Youtube</LinkBase> -{" "}
      <LinkBase href="https://github.com/creativebuilds">Github</LinkBase>
    </Wrapper>
  );
};
